<template>
  <div id="goods_history">
    <div class="goods_list wrap clearfix">
      <div class="item">
        <dl>
          <dt>
            <img src="@/assets/img/06.jpg" alt="" />
          </dt>
          <dd class="title">洗衣机槽泡腾片一盒 12颗装可用半年</dd>
          <dd class="other">
            <span class="price fl">￥2.30</span>
            <span class="fr">0.15 kg/件</span>
          </dd>
          <dd class="num">库存：8324件</dd>
          <dd class="btn">立即购买</dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
#goods_history {
  .goods_list {
    margin-top: 20px;
    margin-bottom: 20px;
    .item {
      width: 226px;
      border: 1px solid #eee;
      margin-bottom: 10px;
      margin-left: 10px;
      float: left;
      &:hover {
        cursor: pointer;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
      }
      dl {
        dt {
          width: 210px;
          margin: 0 auto;
          margin-top: 8px;
          img {
            width: 210px;
            height: 210px;
          }
        }
        dd {
          padding: 0 8px;
          font-size: 14px;
          color: #999;
        }
        .title {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          line-height: 34px;
        }
        .other {
          height: 28px;
          .price {
            color: #ff464e;
            font-size: 16px;
            font-weight: bold;
          }
        }
        .num {
          line-height: 34px;
          color: #333;
          border-top: 1px solid #efefef;
          border-bottom: 1px solid #efefef;
        }
        .btn {
          border-radius: 5px;
          color: #ff464e;
          border: 1px solid #ff464e;
          line-height: 28px;
          width: 180px;
          text-align: center;
          margin: 10px auto;
          &:hover {
            background: #ff464e;
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
